import React from "react"

import { FooterStyles } from "./styles/FooterStyles"

const Footer = () => {
  return (
    <FooterStyles>
      <p>Geek Life Development, 2020</p>
    </FooterStyles>
  )
}

export default Footer
